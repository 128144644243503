module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Apex Auctions South Africa","short_name":"Apex Auctions South Africa","start_url":"/","background_color":"#1e3a8a","theme_color":"#1e3a8a","display":"minimal-ui","icon":"src/images/Apex-Auctions_Icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b30f13c253ac010ea3c24233667ca0f2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"apex-za","routes":[{"type":"page","uid":"index","path":"/"},{"type":"page","path":"/:uid/"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
